import WPhoto_MouseOverPhotoComponent from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/skinComps/BasicWPhoto/MouseOverPhoto.skin';
import WPhoto_MouseOverPhotoController from '@wix/thunderbolt-elements/src/components/WPhoto/viewer/WPhoto.controller';


const WPhoto_MouseOverPhoto = {
  component: WPhoto_MouseOverPhotoComponent,
  controller: WPhoto_MouseOverPhotoController
};


export const components = {
  ['WPhoto_MouseOverPhoto']: WPhoto_MouseOverPhoto
};

